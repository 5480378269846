'use client'
import { trackGoal } from 'lib/analytics'
import { cx } from 'lib/cx'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export const BackToTopButton = (props: JSX.IntrinsicElements['button']) => {
  const [isVisible, setIsVisible] = useState(false)
  const pathname = usePathname()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    trackGoal('click:scrollToTop', {
      props: {
        page: pathname,
      },
    })
  }

  useEffect(() => {
    let lastScrollPosition = 0
    const handleScroll = (e: Event) => {
      const currentScrollPosition = window.scrollY
      const showButton = currentScrollPosition > 1500
      if (currentScrollPosition > lastScrollPosition && showButton) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
      lastScrollPosition = window.scrollY
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <button
      className={cx(
        props.className,
        'right-12 transition-all lg:right-24  z-5 fixed',
        isVisible ? 'bottom-12 lg:bottom-24' : '-bottom-128',
      )}
      {...props}
      onClick={scrollToTop}
      aria-label="Nach oben scrollen"
    >
      <svg
        className="shadow rounded-full"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
      >
        <g data-name="Gruppe 3445" transform="translate(-792 -7599)">
          <circle
            data-name="Ellipse 698"
            cx="25"
            cy="25"
            r="25"
            transform="translate(792 7599)"
            fill="#fff"
          />
          <g
            data-name="Gruppe 3443"
            fill="none"
            stroke="#1e1e1e"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          >
            <path data-name="Linie 24" d="M817.002 7632.502v-17.5" />
            <path data-name="Pfad 2312" d="m812 7620.003 5-5 5 5" />
          </g>
        </g>
      </svg>
    </button>
  )
}
