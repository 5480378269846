declare global {
  interface Window {
    plausible: any
  }
}

if (typeof window !== 'undefined') {
  window.plausible =
    window.plausible ||
    function () {
      ;(window.plausible.q = window.plausible.q || []).push(arguments)
    }
}

export const trackGoal = (goal: string, props?: { props: Object }): void => {
  if (window.plausible) {
    window.plausible(goal, props)
  }
}
