import { cx } from 'lib/cx'
import React from 'react'
import styles from './Overline.module.css'

export function Overline(
  props: React.PropsWithChildren<{ small?: boolean; className?: string }>,
) {
  return (
    <span className={cx(styles.overline, props.className)}>
      {props.children}
    </span>
  )
}
