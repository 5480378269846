function rem(px) {
  return `${px / 16}rem`
}

module.exports = {
  content: [
    './app/**/*.tsx',
    './app/**/*.css',
    './components/**/*.tsx',
    './components/**/*.css',
    './lib/**/*.ts',
    './lib/**/*.css',
    './blocks/**/*.tsx',
    './blocks/**/*.css',
  ],
  important: '#__next',
  theme: {
    fontFamily: {},
    fontSize: {
      200: [rem(200), { lineHeight: rem(220) }],
      180: [rem(180), { lineHeight: rem(198) }],
      80: [rem(80), { lineHeight: rem(88) }],
      70: [rem(70), { lineHeight: rem(77) }],
      60: [rem(60), { lineHeight: rem(66) }],
      50: [rem(50), { lineHeight: rem(55) }],
      40: [rem(40), { lineHeight: rem(44) }],
      36: [rem(36), { lineHeight: rem(40) }],
      30: [rem(30), { lineHeight: rem(33) }],
      27: [rem(27), { lineHeight: rem(30) }],
      22: [rem(22), { lineHeight: rem(28) }],
      20: [rem(20), { lineHeight: rem(26) }],
      18: [rem(18), { lineHeight: rem(20) }],
      16: [rem(16), { lineHeight: rem(18) }],
      14: [rem(14), { lineHeight: rem(22) }],
      12: [rem(12), { lineHeight: rem(14) }],
      8: [rem(8), { lineHeight: rem(8) }],
    },
    fontWeight: {
      bold: 'bold',
      regular: 'normal',
      light: 300,
    },
    lineHeight: {
      220: rem(220),
      88: rem(88),
      77: rem(77),
      66: rem(66),
      55: rem(55),
      44: rem(44),
      33: rem(33),
      40: rem(40),
      39: rem(39),
      30: rem(30),
      28: rem(28),
      26: rem(26),
      22: rem(22),
      20: rem(20),
      18: rem(18),
      14: rem(14),
    },
    // TODO figure this out...
    letterSpacing: {
      0: '0',
      20: '0.02em',
      32: '0.032em',
      50: '0.05em',
    },
    colors: {
      black: '#1e1e1e',
      violet: '#be7dff',
      yellow: '#ffc800',
      blue: '#5fd2ff',
      white: '#fff',
      orange: '#ff5000',
      petrol: '#00f0a5',
      red: '#ff0055',
      background: 'var(--background-color, #fff)',
      current: 'currentColor',
      highlight: 'var(--highlight-color, #ff5000)',
      wave: 'var(--wave-color, var(--highlight-color, #ff5000))',
    },
    spacing: {
      0: '0',
      4: rem(4),
      8: rem(8),
      12: rem(12),
      16: rem(16),
      24: rem(24),
      32: rem(32),
      40: rem(40),
      48: rem(48),
      64: rem(64),
      80: rem(80),
      96: rem(96),
      128: rem(128),
      160: rem(160),
      192: rem(192),
      224: rem(224),
      256: rem(256),
      384: rem(384),
      512: rem(512),
      640: rem(640),
      768: rem(768),
    },
    extend: {
      minWidth: (theme) => theme('spacing'),
      maxWidth: (theme) => theme('spacing'),
      minHeight: (theme) => theme('spacing'),
      maxHeight: (theme) => theme('spacing'),
      backgroundColor: {
        transparent: 'transparent',
      },
    },
  },
  plugins: [],
}
