import { LargeArrow, SmallArrow } from 'components/icons/Arrow'
import React from 'react'

export type ArrowLinkProps = {
  children: React.ReactNode
}

export function ArrowLink({ children }: ArrowLinkProps) {
  return (
    <span className="inline-grid grid-flow-col justify-start text-12 font-bold tracking-42 gap-16 hover:gap-24 pr-8 hover:pr-0 transition-all">
      <span className="uppercase">{children}</span>
      <span>
        <SmallArrow />
      </span>
    </span>
  )
}

export function TitleArrowLink({ children }: ArrowLinkProps) {
  return (
    <span className="group grid grid-flow-col justify-start items-center gap-32 pl-0 pr-8 transition-all hover:pl-8 hover:pr-0">
      <span className="h2">{children}</span>
      <span className="opacity-0 group-hover:opacity-100">
        <LargeArrow />
      </span>
    </span>
  )
}
