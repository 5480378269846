import { ImageFragment, ImageOverrideFragment } from '.generated/dato'
import { cx } from 'lib/cx'
import NextImage, { ImageProps } from 'next/image'
import { preload } from 'react-dom'

export type DatoImageProps = Omit<
  ImageProps,
  'src' | 'width' | 'height' | 'alt' | 'unoptimized' | 'blurDataURL'
> & {
  file: ImageFragment | null
  scale?: number
}

export default function DatoImage({
  file,
  scale,
  placeholder,
  ...nextImageProps
}: DatoImageProps) {
  if (!file) {
    return null
  }

  if (!file.width || !file.height) {
    return <NextImage unoptimized src={file.url} alt={file.alt ?? undefined} />
  }

  const { fill } = nextImageProps

  return (
    <NextImage
      src={file.url}
      width={fill ? undefined : file.width * (scale ?? 1)}
      height={fill ? undefined : file.height * (scale ?? 1)}
      alt={file.alt ?? undefined}
      blurDataURL={file.blurUpThumb ?? undefined}
      placeholder={file.blurUpThumb ? placeholder : 'empty'}
      {...nextImageProps}
    />
  )
}

export function DatoBackgroundImage(props: {
  file: ImageFragment | null
  className?: string
  opacity?: number
}) {
  return (
    <div className={cx(props.className, 'absolute inset-0 -z-10')}>
      <div
        style={{ opacity: props.opacity }}
        className="relative w-full h-full"
      >
        <DatoImage
          file={props.file}
          fill
          style={{ objectFit: 'cover' }}
          placeholder="blur"
        />
      </div>
    </div>
  )
}

type DatoResponsiveGraphicProps = {
  mobile: ImageFragment
  desktop: ImageOverrideFragment | null
  className?: string
  priority?: boolean
}

/**
 * Used to show different SVGs on different viewports
 * WARNING: no regular image optimization applied
 * threrefore this is only to be used with SVGs (via validation in CMS)
 */
export function DatoResponsiveGraphic({
  desktop,
  mobile,
  className,
  priority,
}: DatoResponsiveGraphicProps) {
  if (priority) {
    if (desktop) {
      if (typeof window !== 'undefined') {
        preload(window.innerWidth >= 768 ? desktop.url : mobile.url)
      }
    } else {
      preload(mobile.url)
    }
  }

  return (
    <picture>
      {desktop && (
        <source
          media="(min-width: 768px)"
          width={desktop.width!}
          height={desktop.height!}
          className="hidden"
          srcSet={desktop.url}
        />
      )}
      <img
        src={mobile.url}
        width={mobile.width!}
        height={mobile.height!}
        alt={mobile.alt}
        className={cx('w-auto', className)}
      />
    </picture>
  )
}
