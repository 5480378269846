import { defineRouting } from 'next-intl/routing'
import { createLocalizedPathnamesNavigation } from 'next-intl/navigation'

export const locales = ['de', 'en'] as const

export type Locale = (typeof locales)[number]

export const routing = defineRouting({
  // A list of all locales that are supported
  locales,

  // Used when no locale matches
  defaultLocale: 'de',

  localePrefix: 'as-needed',

  pathnames: {
    '/': '/',
    '/[slug]': '/[slug]',
    '/blog': '/blog',
    '/blog/[slug]': '/blog/[slug]',
    '/kontakt': {
      de: '/kontakt',
      en: '/contact',
    },
    '/ueber-uns': {
      de: '/ueber-uns',
      en: '/about-us',
    },
    '/ueber-uns/arbeitsweise': {
      de: '/ueber-uns/arbeitsweise',
      en: '/about-us/operation',
    },
    '/ueber-uns/engagement': {
      de: '/ueber-uns/engagement',
      en: '/about-us/engagement',
    },
    '/ueber-uns/[slug]': {
      de: '/ueber-uns/[slug]',
      en: '/about-us/[slug]',
    },
    '/projekte': {
      de: '/projekte',
      en: '/projects',
    },
    '/projekte/[slug]': {
      de: '/projekte/[slug]',
      en: '/projects/[slug]',
    },
    '/kompetenzen': {
      de: '/kompetenzen',
      en: '/expertise',
    },
    '/kompetenzen/[slug]': {
      de: '/kompetenzen/[slug]',
      en: '/expertise/[slug]',
    },
    '/erfolgreich': {
      de: '/erfolgreich',
      en: '/success',
    },
    '/jobs': '/jobs',
    '/jobs/[slug]': '/jobs/[slug]',
    '/datenschutz': {
      de: '/datenschutz',
      en: '/privacy',
    },
    '/impressum': {
      de: '/impressum',
      en: '/legal',
    },
  },
})

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const {
  Link,
  redirect,
  usePathname,
  useRouter,
  getPathname,
  permanentRedirect,
} = createLocalizedPathnamesNavigation(routing)

export type Pathname = Parameters<typeof getPathname>[0]['href']
