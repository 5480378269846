'use client'

import { ImageFragment } from '.generated/dato'
import { TitleArrowLink } from 'components/ArrowLink'
import { Container } from 'components/Container'
import DatoImage from 'components/DatoImage'
import { Overline } from 'components/Overline'
import { Link } from 'i18n/routing'
import { cx } from 'lib/cx'
import { useTranslations } from 'next-intl'
import { Fragment, useState } from 'react'
import tailwindConfig from 'tailwind.config'

interface Skill {
  id: string
  colour: { hex: string } | null
  illustration: ImageFragment
  slug: string
  heading: string
  shortDescription: string
}

interface Props {
  skills: Skill[]
}

export function Gallery({ skills }: Props) {
  const [activeSkill, setActiveSkill] = useState<Skill>()
  const t = useTranslations('gallery')

  return (
    <section
      className="transition-all duration-400 hidden xl:block"
      style={{
        backgroundColor:
          activeSkill?.colour?.hex ?? tailwindConfig.theme.colors.orange,
      }}
      onMouseLeave={() => setActiveSkill(undefined)}
    >
      <Container className="grid lg:grid-cols-2 min-h-0">
        <div className="py-64 lg:py-192 grid gap-48">
          <Overline>{t('whatWeOffer')}</Overline>
          {skills.map((skill) => (
            <Fragment key={skill.id}>
              <span
                key={skill.id}
                className="inline"
                onMouseOver={() => setActiveSkill(skill)}
              >
                <Link
                  href={{
                    pathname: '/kompetenzen/[slug]',
                    params: { slug: skill.slug },
                  }}
                >
                  <TitleArrowLink>{skill.heading}</TitleArrowLink>
                </Link>
              </span>
            </Fragment>
          ))}
        </div>
        <div className="grid justify-items-center items-center relative overflow-hidden">
          <div className="relative w-full max-w-640">
            <div
              className="absolute transition-all duration-300 w-full grid gap-32"
              style={{
                transform: `translateY(${calculateOffset(
                  activeSkill && skills.indexOf(activeSkill),
                )}`,
              }}
            >
              {skills.map((skill, index) => (
                <div
                  key={skill.id}
                  className="grid grid-cols-3 items-center gap-40"
                >
                  <Illustration
                    active={
                      activeSkill === skill || (!activeSkill && index === 1)
                    }
                    image={skill.illustration!}
                  />
                  <Overline
                    className={cx(
                      'whitespace-pre-wrap text-black transition-opacity',
                      activeSkill !== skill && 'opacity-0',
                    )}
                  >
                    {skill.shortDescription}
                  </Overline>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

function Illustration(props: { image: ImageFragment; active: boolean }) {
  return (
    <div
      className={cx(
        'text-black aspect-square col-span-2 p-32 transition-opacity duration-300',
        !props.active && 'opacity-0',
      )}
    >
      <DatoImage file={props.image} scale={3} />
    </div>
  )
}

function calculateOffset(activeImage: number | undefined) {
  switch (activeImage) {
    case 0:
      return `-17.5%`
    case 2:
      return `-82.5%`
    case 1:
    case undefined:
    default:
      return `-50%`
  }
}
