import { cx } from 'lib/cx'
import React from 'react'
import styles from './Container.module.css'

export type ContainerProps = {
  className?: string
  width?: 'wide' | 'narrow' | 'full'
  height?: 'auto' | number
  children: React.ReactNode
}

export function Container(props: ContainerProps) {
  const width = props.width ?? 'wide'
  const height = props.height ?? 'auto'

  return (
    <div
      className={cx(
        styles.main,
        width === 'wide' && styles.wide,
        width === 'narrow' && styles.narrow,
        props.className,
      )}
      style={{ height }}
    >
      {props.children}
    </div>
  )
}
